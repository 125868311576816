import React from "react";
import {
	useInstitutionFormState,
	INSTITUTION_FORM_STEPS,
} from "../_useInstitutionFormState";
import { InstitutionStepPage } from "../_InstitutionStepPage";
import { useApplicationContactPageFields } from "@components/ApplicationStepPage/common/Contact/useApplicationContactFields";

const InstitutionContact: React.FunctionComponent = () => {
	const formData = useInstitutionFormState(
		INSTITUTION_FORM_STEPS.CONTACT.formStep
	);

	const { body, onSubmit } = useApplicationContactPageFields(
		formData,
		"email@company.com"
	);

	return (
		<InstitutionStepPage
			currentStepIndex={INSTITUTION_FORM_STEPS.CONTACT.formStep}
			onButtonClick={() =>
				onSubmit(() =>
					formData.navigateToStep(
						INSTITUTION_FORM_STEPS.INSTITUTION.formStep
					)
				)
			}
		>
			{body}
		</InstitutionStepPage>
	);
};

export default InstitutionContact;
